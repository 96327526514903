<template>
	<header class="bg-secondary mb-48">
		<div class="lg:flex lg:text-left text-center items-center justify-between gap-x-12 container mx-auto 2xl:pt-16 pt-10 px-4 max-w-screen-xl">
			<div class="lg:w-1/2 lg:mb-0 mb-12">

				<!--Page Title-->
				<h1 class="2xl:text-6xl lg:text-5xl text-4xl xl:leading-2xl lg:leading-3xl leading-tight xl:mb-8 lg:mb-6 mb-4 relative tracking-tight font-black">
					Streamlining <span class="text-theme">ESOP</span> Plans for Founders
				</h1>
				<h2 class="2xl:text-2xl text-xl font-bold mb-12">
					Insights and Automation Strategies. Let’s tell stories about world of ESOPs.
				</h2>

				<form v-if="false">
					<div class="flex justify-between bg-white p-2 rounded-xl gap-4">
						<input type="text" placeholder="Type your email..." class="bg-transparent w-full focus:outline-none pl-3">
						<button type="submit" class="font-extrabold text-theme bg-secondary rounded-xl whitespace-nowrap py-3 px-5 hover:bg-theme hover:text-white transition-all">Sign Up for Newsletter</button>
					</div>
				</form>
			</div>

			<!--Hero Image-->
			<img class="relative lg:w-1/2 rounded-lg max-w-[700px] mx-auto z-10" style="margin-bottom: -120px" src="/images/blog/blog-hero.png" alt="">
		</div>

		<!--Wave BG-->
		<figure class="bg-wave-white bg-w-full bg-no-repeat bg-bottom h-12 w-full block transform lg:translate-y-1"></figure>
	</header>
</template>

<script>
	export default {
		name: 'BlogHeader',
	}
</script>