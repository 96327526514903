<template>
	<div class="relative mx-auto w-full h-full">
		<div style="padding: 0 0 56.25%; transform: scale(0.82) translateY(14%);" class="w-full h-0 relative z-10 rounded-lg overflow-hidden">
			<img :src="source" style="transform: translateY(-50%);" alt="" loading="lazy" class="w-full absolute top-0 top-1/2">
		</div>
		<img class="absolute top-0 left-0 right-0 bottom-0" src="/images/video-background.svg" alt="">
	</div>
</template>
<script>
export default {
	name: 'BlogThumbnail',
	props: [
		'source'
	],
}
</script>