<template>
	<div v-if="article" class="page-container mx-auto lg:flex justify-between gap-16 items-center mb-24">
		<div class="lg:mb-0 mb-8">
			<img class="lg:w-auto lg:max-w-md w-full rounded-xl" :src="'https://blog.optiomat.com' + article.attributes.cover.data.attributes.url" alt="">
		</div>

		<div class="">
			<span class="bg-theme rounded-full text-white font-bold text-xs py-1 px-2.5 inline-block mb-6">
				Featured Post
			</span>

			<p class="text-theme font-bold mb-6">
				{{ countReadingTime(article.attributes.content) }} Min. Reading / Published at {{ convertDate(article.attributes.createdAt) }}
			</p>

			<router-link :to="{name: 'BlogArticle', params: { article: article.id }}" tag="a" class="hover:text-theme transition-all">
				<h2 class="text-3xl font-black leading-snug mb-1 mb-6">
					{{ article.attributes.title }}
				</h2>
			</router-link>
			<p class="font-bold italic text-lg mb-6">
				{{ createTeaser(article.attributes.content) }}
			</p>
			<router-link :to="{name: 'BlogArticle', params: { article: article.id }}" tag="a">
				<button class="bg-secondary rounded-xl py-3 px-4 inline-block flex items-center mb-6">
					<img src="/images/blog/download-document-icon.svg" alt="" class="mr-2.5">
					<span class="text-theme font-extrabold text-normal">Read Entire Post</span>
				</button>
			</router-link>
		</div>
	</div>
</template>

<script>
	import axios from "axios";
	import {marked} from "marked";

	export default {
		name: 'BlogFeatured',
		data() {
			return {
				article: null,
			}
		},
		methods: {
			convertDate(datetime) {
				let date = new Date(datetime);

				let options = {
					year: 'numeric',
					month: 'long',
					day: 'numeric',
					hour: 'numeric',
					minute: 'numeric',
					timeZone: 'UTC'
				};

				return date.toLocaleString('en-US', options)
			},
			countReadingTime(content) {
				let words = content.split(" ");

				let readingSpeed = 200;
				let readingTime = words.length / readingSpeed;

				return Math.ceil(readingTime)
			},
			createTeaser(content) {
				let markedText = marked(content)

				let plainText = markedText.replace(/(<([^>]+)>)/ig, "")

				return plainText.substring(0, 170) + '...';
			}
		},
		mounted() {
			axios.get(`https://blog.optiomat.com/api/blogs/?filters[featured][$eq]=true&populate=*`)
				.then((response) => {
					this.article = response.data.data[0]
				})
				.catch((error) => alert('Sadly, there is an error :(('))
		}
	}
</script>