<template>
	<div class="page-container">
		<span v-if="$slots.default" class="text-theme text-3xl mt-14 block">Read the Latest Articles:</span>
		<section v-for="(article, i) in articles" :key="i" class="xl:py-24 lg:py-14 py-10 flex gap-8 md:flex-nowrap flex-wrap md:items-center md:space-y-0 space-y-6 mb-12">
			<div class="lg:w-7/12 w-full" :class="{'lg:order-2': i % 2 !== 0}">
				<div data-aos="fade-up">
					<h3 class="lg:text-3xl text-2xl font-black leading-snug mb-4 capitalize">
						<router-link :to="{name: 'BlogArticle', params: { article: article.id }}" tag="a" class="hover:text-theme transition-all">
							{{ article.attributes.title }}
						</router-link>
					</h3>
					<p class="text-theme font-bold mb-4">
						{{ countReadingTime(article.attributes.content) }} Min. Reading / Published at {{ convertDate(article.attributes.createdAt) }}
					</p>
					<h4 class="font-bold italic text-lg">
						{{ createTeaser(article.attributes.content) }}
					</h4>
				</div>
			</div>
			<kinesis-container data-aos="fade-up" event="scroll" tag="div" class=" lg:w-5/12 w-full" :class="{'': i % 2 === 0}">
				<kinesis-element
					tag="div"
					:strength="60"
					type="translate"
				>
					<BlogThumbnail :source="'https://blog.optiomat.com' + article.attributes.cover.data.attributes.url" />
				</kinesis-element>
			</kinesis-container>
		</section>
	</div>
</template>

<script>
	import BlogThumbnail from "./BlogThumbnail";
	import {marked} from "marked";

	export default {
		name: 'BlogListOfArticles',
		props: [
			'articles',
		],
		components: {
			BlogThumbnail,
		},
		methods: {
			convertDate(datetime) {
				let date = new Date(datetime);

				let options = {
					year: 'numeric',
					month: 'long',
					day: 'numeric',
					hour: 'numeric',
					minute: 'numeric',
					timeZone: 'UTC'
				};

				return date.toLocaleString('en-US', options)
			},
			countReadingTime(content) {
				let words = content.split(" ");

				let readingSpeed = 200;
				let readingTime = words.length / readingSpeed;

				return Math.ceil(readingTime)
			},
			createTeaser(content) {
				let markedText = marked(content)

				let plainText = markedText.replace(/(<([^>]+)>)/ig, "")

				return plainText.substring(0, 170) + '...';
			}
		}
	}
</script>